import { useContext, useEffect, useRef, useState } from "react";
import {
  LanguageIcon,
  LanguageLi,
  LanguageLink,
  LanguageSelectorWrapper,
  LanguageSpan,
  LanguageUl,
  StyledCheck28,
  Wrapper,
} from "./LanguageChooser.styled";
import { CultureContext, Cultures, UiContext } from "@sop/unify-ui-react";

const LanguageChooser = () => {
  const [openLanguage, setOpenLanguage] = useState(false);
  const uiContext = useContext(UiContext);
  const cultureContext = useContext(CultureContext);
  const localizations = uiContext.localizations ?? [
    { culture: Cultures.SWEDISH, displayName: "Svenska" },
  ];
  const ref = useRef<HTMLDivElement>(null);
  document.documentElement.lang = cultureContext.culture ?? "sv-SE";

  const onLanguageLinkClick = (e: any) => {
    e.preventDefault();
    setOpenLanguage(!openLanguage);
  };

  const onLanguageSelectorClick = (culture: any) => {
    cultureContext.setCulture(culture);
    document.documentElement.lang = culture;
    setOpenLanguage(false);
  };

  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenLanguage(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <Wrapper ref={ref}>
      <LanguageLink
        onClick={(e) => onLanguageLinkClick(e)}
        aria-label="Language selector"
      >
        <LanguageIcon aria-hidden="true" />
        <span>
          {
            localizations.find((l) => l.culture === cultureContext.culture)
              ?.displayName
          }
        </span>
      </LanguageLink>
      {openLanguage && (
        <LanguageSelectorWrapper>
          <LanguageUl>
            {localizations.map((l) => (
              <LanguageLi
                key={l.culture}
                onClick={() => onLanguageSelectorClick(l.culture)}
                aria-label={l.displayName}
              >
                {l.culture === cultureContext.culture && <StyledCheck28 />}
                <LanguageSpan selected={l.culture === cultureContext.culture}>
                  {l.displayName}
                </LanguageSpan>
              </LanguageLi>
            ))}
          </LanguageUl>
        </LanguageSelectorWrapper>
      )}
    </Wrapper>
  );
};

export default LanguageChooser;
