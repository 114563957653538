import {
  BodyM,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  HeadingM,
  TextField,
} from "@sop/ui-library";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { updateAccountInformation } from "../../../shared/services/spPlusService";
import { UpdateAccountInfoRequest } from "./ContactInformationDialog.interface";
import Snackbar, { SnackBarState } from "../Snackbar";
import { useT } from "../../../shared/internationalization";

type ContactInformationDialogProps = {
  userPhoneNumber?: string;
  userEmail?: string;
};

export default function ContactInformationDialog({
  userPhoneNumber,
  userEmail,
}: ContactInformationDialogProps) {
  const phrases = useT("SpPlus");

  const [email, setEmail] = useState<string | undefined>(userEmail);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userPhoneNumber
  );
  const [open, setOpen] = useState(true);
  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });
  const [showEmailValidationError, setShowEmailValidationError] =
    useState(false);

  const mutation = useMutation({
    mutationFn: (accountInfo: UpdateAccountInfoRequest) => {
      return updateAccountInformation(accountInfo);
    },
  });

  const updateAccountInfo = () => {
    if (!email || !phoneNumber) return;
    mutation.mutate({ email: email, phoneNumber: phoneNumber });
  };

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(() => {
    if (mutation.data?.success) {
      setSnackbarState({
        open: true,
        severity: "success",
        message: phrases("ContactInformationDialogSuccessMessage"),
        alertTitle: phrases("ContactInformationDialogSuccessTitle"),
      });
      setOpen(false);
    }
    if (mutation.data?.success === false) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: phrases("ContactInformationDialogErrorMessage"),
        alertTitle: phrases("ContactInformationDialogErrorTitle"),
      });
    }
  }, [mutation.data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleEmailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
    );

    const emailIsValid = regex.test(e.target.value);

    if (!emailIsValid) {
      setShowEmailValidationError(true);
    } else {
      setShowEmailValidationError(false);
    }
  };

  return (
    <>
      <Snackbar
        open={snackbarState.open}
        severity={snackbarState.severity}
        message={snackbarState.message}
        alertTitle={snackbarState.alertTitle}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Dialog open={open}>
        <DialogTitle>
          <HeadingM>{phrases("ContactInformationDialogHeading")}</HeadingM>
          <Button
            actionTheme="black"
            icon="close28"
            iconOnly
            onClick={handleClose}
            size="small"
            style={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
            }}
            variant="outline"
          />
        </DialogTitle>
        <DialogContent style={{ display: "grid", gap: "2.25rem" }}>
          <BodyM>{phrases("ContactInformationDialogDescription")}</BodyM>

          <TextField
            label={phrases("ContactInformationDialogEmailLabel")}
            disabled={mutation.isPending}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "var(--color-black)",
              },
            }}
            inputProps={{
              onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
                handleEmailValidation(e),
            }}
            placeholder={phrases("ContactInformationDialogEmailPlaceholder")}
            error={showEmailValidationError}
            helperText={
              showEmailValidationError &&
              phrases("ContactInformationDialogEmailValidationError")
            }
            defaultValue={email}
            onChange={handleEmailChange}
          />
          <TextField
            label={phrases("ContactInformationDialogPhoneNumberLabel")}
            disabled={mutation.isPending}
            InputLabelProps={{
              shrink: true,
              style: {
                color: "var(--color-black)",
              },
            }}
            placeholder={phrases(
              "ContactInformationDialogPhoneNumberPlaceholder"
            )}
            defaultValue={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            actionTheme="blue"
            linkText={phrases("ContactInformationDialogButtonText")}
            onClick={updateAccountInfo}
            size="medium"
            variant="primary"
            disabled={
              showEmailValidationError ||
              !email ||
              !phoneNumber ||
              mutation.isPending
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
