import PrivateInsurance from "./PrivateInsurance";
import { usePermissions } from "../../security";
import { Banner, KeyFigure } from "@sop/ui-library";
import { getUserInformation } from "../../shared/services/unifyService";
import { useEffect, useState } from "react";
import { UserInformation } from "../../shared/interfaces";
import { useT } from "../../shared/internationalization";
import PensionPrognosisGraph from "./PensionPrognosisGraph/PensionPrognosisGraph";
import ContactInformationDialog from "./ContactInformationDialog";
import { useQuery } from "@tanstack/react-query";
import { getAccountInformation } from "../../shared/services/spPlusService";
import Footer from "../Footer/Footer";
import MyServices from "../MyServices";
import MyInformation from "../MyInformation";
import WealthSection from "./Wealth/WealthSection";

export default function Plus() {
  const permissions = usePermissions();
  const phrases = useT("SpPlus");
  const [user, setUser] = useState<UserInformation | undefined>(undefined);

  const { data, isPending } = useQuery({
    queryKey: ["getAccountInformation"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getAccountInformation();
      return result;
    },
    enabled: true,
  });

  const userEmail = data?.email;
  const userPhone = data?.phoneNumber;

  useEffect(() => {
    async function getUser() {
      const user = await getUserInformation();
      setUser(user);
    }
    getUser();
  }, []);

  if (user === undefined) return null;

  return (
    <>
      <Banner
        contentTheme="white"
        heading={phrases("BannerTitle").replace("__NAME__", user?.fullName)}
        target="_blank"
        text={phrases("BannerDescription")}
        theme="black"
        backgroundImage={{
          url: "/images/spplus.svg",
          overlay: "UpRight",
          darken: true,
        }}
        transparentMenuGap={true}
      />
      {(permissions.isWealthCustomer ||
        permissions.isPensionAdvisoryCustomer) && <WealthSection />}
      {permissions.isPensionCustomer && <PensionPrognosisGraph />}
      {permissions.isPrivateInsuranceUsers && <PrivateInsurance />}
      {!isPending && (!userPhone || !userEmail) && (
        <ContactInformationDialog
          userPhoneNumber={userPhone}
          userEmail={userEmail}
        />
      )}
      <MyServices />
      <MyInformation isSpPlus />
      <Footer isSpPlus />
    </>
  );
}
