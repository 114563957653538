import styled from "styled-components";
import { Card, SignColorNumber } from "@sop/ui-library";

export const GraphSection = styled(Card)`
  && {
    display: grid;
    gap: 1rem;
    padding-bottom: 0;
  }
`;

export const GraphContainer = styled.div`
  @media (min-width: 768px) {
    padding-top: 1rem;
  }
`;

export const ChipContainer = styled(Card)`
  display: flex;
  background-color: white;
  padding-top: 1rem;
`;

export const LabelContainer = styled.div<{ isMobile?: boolean }>`
  > span {
    margin-left: 0;
    ${({ isMobile }) =>
      isMobile ? "line-height: 1.3125rem;;" : "line-height: 1.75rem;"}
  }
`;

export const NoScrollbar = styled.div`
  position: relative;
  width: 100%;
  & .react-horizontal-scrolling-menu--inner-wrapper {
    display: flex;
  }
  & .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  & .react-horizontal-scrolling-menu--scroll-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    overflow-y: hidden;
    gap: 1rem;
  }
  & .react-horizontal-scrolling-menu--arrow-left {
    position: absolute;
    z-index: 1;
  }
  & .react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    z-index: 1;
    right: 0;
  }
  & .react-horizontal-scrolling-menu--item[data-index="0"] > div {
    margin-left: 0;
  }
  & .react-horizontal-scrolling-menu--item {
    border-right: 1px solid #ebeaea;
  }
`;

export const ArrowButton = styled.button`
  cursor: pointer;
  display: flex;
  flexdirection: column;
  background: transparent;
  border: none;
  align-items: center;
  height: 40px;
  border-radius: 3rem;
  margin-top: 0.3rem;
  background-color: rgba(0, 0, 0, 0.04);
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
