import { InstanceParams } from "@jonkoops/matomo-tracker-react/lib/types";
import { MatomoUserOptions, UserInformation } from "../interfaces";
import { unifyService } from "../services";
import { matomoSiteProperties } from "../utils";

export default async function getMatomoUserOptions(): Promise<InstanceParams> {
  const userinformation: UserInformation | undefined =
    await unifyService.getUserInformation();
  const matomoUserOptions: MatomoUserOptions = {
    urlBase: matomoSiteProperties.baseUrl,
    siteId: matomoSiteProperties.siteId,
    configurations: {
      disableCookies: userinformation
        ? !userinformation.hasGivenCookieConsent
        : true,
    },
  };

  return matomoUserOptions;
}
