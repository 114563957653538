export interface InitiateCreateAccountResult {
  signingId?: string;
  sameDeviceStartUrl?: string;
  securityCodeToken?: string;
  accountStatus?: AccountStatus;
  success?: boolean;
}

export enum AccountStatus {
  AlreadyExists = 0,
  DoesNotExist = 1,
}

export interface GetCreateAccountStatusResult {
  status?: Status;
  refreshedSecurityCode?: string;
}

export enum Status {
  IN_PROGRESS = 0,
  COMPLETE = 1,
  CANCELLED = 2,
  EXPIRED = 3,
  SIGNING_COULD_NOT_BE_FOUND = 4,
  COMPLETE_CREATING_ACCOUNT = 5,
  COMPLETE_CREATED_ACCOUNT = 6,
  FAILED_CREATING_ACCOUNT = 7,
}
