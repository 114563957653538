import { Check28, Globe28, Link, styleBodyM } from "@sop/ui-library";
import styled from "styled-components";

export const LanguageIcon = styled(Globe28)`
  && {
    margin-right: 7px;
    top: -1px;
    position: relative;
    width: 28px;
    height: 28px;
    display: inline;
    color: var(--color-black);
  }
`;

export const LanguageLink = styled.button`
  && {
    ${styleBodyM};
    padding: 0;
    cursor: pointer;
    border: none;
    background-color: initial;
    vertical-align: baseline;
    color: var(--color-black);
    span {
      border: none;
    }
    :hover,
    :focus-visible {
      outline: none;
      span {
        border-bottom: 1px solid var(--color-black);
      }
    }
    :focus-visible {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const LanguageSelectorWrapper = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 3.2rem;
  box-shadow: 0px -28px 28px 0px rgba(0, 0, 0, 0.2);
  width: 15rem;
  background: var(--background-default);
  border-radius: 4px;
`;

export const LanguageUl = styled.ul`
  padding: 0.8125rem 1.3125rem;
  margin: 0;
  list-style-type: none;
  color: var(--color-black);
  display: grid;
`;

export const LanguageLi = styled.button`
  border: none;
  background-color: initial;
  padding: 0.5rem 0;
  cursor: pointer;
  text-align: left;
  :focus-visible {
    outline: none;
    background-color: rgba(0, 0, 0, 0.04);
    > span {
      border-bottom: 1px solid var(--color-black);
    }
  }
`;

export const LanguageSpan = styled.span<{ selected: boolean }>`
  transition: transform 0.2s;
  ${styleBodyM};
  ${(props) =>
    props.selected
      ? ""
      : `
    :hover {
      border-bottom: 1px solid var(--color-black);
    }
    `}
`;

export const StyledCheck28 = styled(Check28)`
  margin: -7px 7px 0 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  align-self: flex-end;
  padding: 0.875rem 1rem;
`;
