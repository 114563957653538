import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, FC } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const MatomoWrapper: FC<Props> = ({ children }: Props) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return <>{children}</>;
};

export default MatomoWrapper;
