import { BigArrowLeft28, BigArrowRight28, Chip } from "@sop/ui-library";
import {
  ArrowButton,
  ChipContainer,
  NoScrollbar,
} from "./WealthSection.styled";
import useIsMobile from "../../../shared/utils";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ChipLabel from "./ChipLabel";
import DragDealer from "./DragDealer";
import { ChipOptions } from "./WealthSection.interface";
import { useCallback, useContext, useRef } from "react";

export default function Chips({
  chipData,
  handleChipClick,
}: {
  chipData: ChipOptions[];
  handleChipClick: any;
}) {
  const isMobile = useIsMobile();
  const dragState = useRef(new DragDealer());

  const handleDrag = (scrollContainer: any) => (ev: React.MouseEvent) =>
    dragState.current.dragMove(ev, (posDiff: any) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });
  const onMouseDown = useCallback(
    () => dragState.current.dragStart,
    [dragState]
  );
  const onMouseUp = useCallback(
    () => dragState.current.dragStop,
    [dragState]
  );
  return (
    <ChipContainer>
      <NoScrollbar>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={handleDrag}
          onWheel={onWheel}
        >
          {chipData.map((chip) => (
            <Chip
              key={chip.id}
              sx={{
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
              theme="lightgray"
              selected={chip.selected}
              size={isMobile ? "small" : "medium"}
              label={
                <ChipLabel
                  color="lightgray"
                  selected={chip.selected}
                  number={chip.number}
                  id={chip.id}
                />
              }
              onClick={() => {
                handleChipClick(chip.id);
              }}
              disabled={!chip.number}
            />
          ))}
        </ScrollMenu>
      </NoScrollbar>
    </ChipContainer>
  );
}

const LeftArrow = () => {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  const onClick = () =>
    visibility.scrollToItem(visibility.getPrevElement(), "smooth", "start");
  return (
    <ArrowButton
      disabled={isFirstItemVisible}
      onClick={onClick}
      style={{ opacity: isFirstItemVisible ? 0 : 1 }}
    >
      <BigArrowLeft28 />
    </ArrowButton>
  );
};

const RightArrow = () => {
  const visibility = useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  const onClick = () =>
    visibility.scrollToItem(visibility.getNextElement(), "smooth", "end");

  return (
    <ArrowButton
      disabled={isLastItemVisible}
      onClick={onClick}
      style={{ opacity: isLastItemVisible ? 0 : 1 }}
    >
      <BigArrowRight28 />
    </ArrowButton>
  );
};

function onWheel(apiObj: any, ev: any) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
}
