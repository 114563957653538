import {
  Benefits28,
  Button,
  Divider,
  Document28,
  Drawer,
  Home28,
  Insurance28,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LogoSmallDefault42,
  LogoDefault56,
  Wealth28,
  Pension28,
  Hr28,
  ListItemButton,
} from "@sop/ui-library";
import LeftMenuProps from "./LeftMenuProps";
import { MenuContainer, StyledSkipLink, Spacer } from "./LeftMenu.styled";
import LanguageChooser from "../LanguageChooser/LanguageChooser";
import { usePermissions } from "../../../security";
import { redirectUrls } from "../../../shared/utils";
import { useT } from "../../../shared/internationalization";

const LeftMenu = ({ isMobile = false, open, onClick }: LeftMenuProps) => {
  const permissions = usePermissions();
  const leftMenuPhrases = useT("LeftMenu");
  return (
    <Drawer
      anchor={isMobile ? "top" : "left"}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: isMobile ? "100% !important" : "268px !important",
          height: "100%",
        },
        width: isMobile ? "100% !important" : "268px !important",
        height: "100%",
        zIndex: 3,
      }}
      theme="lightgray"
      variant={isMobile ? "temporary" : "permanent"}
      transitionDuration={0}
      open={open}
    >
      <StyledSkipLink href="#maincontent">
        {leftMenuPhrases("SkipMain")}
      </StyledSkipLink>
      <MenuContainer $isMobile={isMobile} href="/" ariaLabel={leftMenuPhrases("HomeMenu")}>
        {isMobile && <LogoSmallDefault42 aria-hidden="true"/>}
        {!isMobile && <LogoDefault56 aria-hidden="true"/>}
      </MenuContainer>
      {isMobile && (
        <Button
          actionTheme="black"
          icon="close28"
          iconOnly
          onClick={onClick}
          size="small"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
          variant="outline"
        />
      )}
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton selected component="a" href="/" aria-label={leftMenuPhrases("HomeMenu")}>
            <ListItemIcon>
              <Home28 />
            </ListItemIcon>
            <ListItemText primary={leftMenuPhrases("HomeMenu")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List component="nav">
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href={redirectUrls.pensionBaseUrl + "/MeetingDocuments/MyDocuments"}
            aria-label={leftMenuPhrases("DocumentsMenu")}
          >
            <ListItemIcon>
              <Document28 />
            </ListItemIcon>
            <ListItemText primary={leftMenuPhrases("DocumentsMenu")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider variant="middle" />      
      {(permissions.isWealthCustomer || permissions.isPensionAdvisoryCustomer) && (
        <>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={redirectUrls.wealthBaseUrl}
                aria-label={leftMenuPhrases("WealthMenu")}
              >
                <ListItemIcon>
                  <Wealth28 />
                </ListItemIcon>
                <ListItemText primary={leftMenuPhrases("WealthMenu")} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider variant="middle" />
        </>
      )}
      {permissions.isPensionCustomer && (
        <>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={redirectUrls.pensionBaseUrl}
                aria-label={leftMenuPhrases("PensionMenu")}
              >
                <ListItemIcon>
                  <Pension28 />
                </ListItemIcon>
                <ListItemText primary={leftMenuPhrases("PensionMenu")} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider variant="middle" />
        </>
      )}
      {permissions.isPrivateInsuranceUsers && (
        <>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={redirectUrls.privateInsuracesBaseUrl}
                aria-label={leftMenuPhrases("PrivateInsurancesMenu")}
              >
                <ListItemIcon>
                  <Insurance28 />
                </ListItemIcon>
                <ListItemText
                  primary={leftMenuPhrases("PrivateInsurancesMenu")}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider variant="middle" />
        </>
      )}
      {permissions.isPayrollCustomer && (
        <>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={redirectUrls.payrollBaseUrl}
                aria-label={leftMenuPhrases("PayrollMenu")}
              >
                <ListItemIcon>
                  <Hr28 />
                </ListItemIcon>
                <ListItemText primary={leftMenuPhrases("PayrollMenu")} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider variant="middle" />
        </>
      )}
      {permissions.isBenefitsCustomer && (
        <>
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href={redirectUrls.benefitsBaseUrl}
                aria-label={leftMenuPhrases("BenefitsMenu")}
              >
                <ListItemIcon>
                  <Benefits28 />
                </ListItemIcon>
                <ListItemText primary={leftMenuPhrases("BenefitsMenu")} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider variant="middle" />
        </>
      )}
      <Spacer></Spacer>
      <LanguageChooser />
    </Drawer>
  );
};

export default LeftMenu;
