import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "../../App";
import ErrorPage, { ErrorPageType } from "../../components/ErrorPage/ErrorPage";
import { ROUTES } from "./router.constants";

const baseAndPlusPath = [ROUTES.BASE, ROUTES.PLUS].map((path) => ({
  path,
  element: <App />,
}));

const router = createBrowserRouter([
  ...baseAndPlusPath,
  {
    path: ROUTES.ERROR,
    element: <ErrorPage errorPageType={ErrorPageType.SigningFailed} />,
  },
  {
    path: ROUTES.CANCELLED,
    element: <ErrorPage errorPageType={ErrorPageType.SigningCancelled} />,
  },
  {
    path: ROUTES.EXPIRED,
    element: <ErrorPage errorPageType={ErrorPageType.SigningExpired} />,
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <ErrorPage errorPageType={ErrorPageType.NotFound} />,
  },
  {
    path: "*",
    element: <Navigate to={ROUTES.NOT_FOUND} replace />,
  },
]);

export default router;
