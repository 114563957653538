import { Hero, Section } from "@sop/ui-library";
import { useT } from "../../shared/internationalization";

export enum ErrorPageType {
  NotFound = "NotFound",
  SigningCancelled = "SigningCancelled",
  SigningExpired = "SigningExpired",
  SigningFailed = "SigningFailed",
}

interface ErrorPageProps {
  errorPageType: ErrorPageType;
}

interface HeroCta {
  heading: string;
  url: string;
}

function ErrorPage({ errorPageType }: ErrorPageProps) {
  const phrases = useT("ErrorPage");

  let cta: HeroCta;
  let heading: string;
  let introduction: string;

  switch (errorPageType) {
    case ErrorPageType.NotFound:
      cta = {
        heading: phrases("CtaHeading"),
        url: "/",
      };
      heading = phrases("NotFoundHeading");
      introduction = phrases("NotFoundText");
      break;
    case ErrorPageType.SigningCancelled:
      cta = {
        heading: phrases("CtaHeading"),
        url: "/",
      };
      heading = phrases("SigningCancelledHeading");
      introduction = phrases("SigningCancelledText");
      break;
    case ErrorPageType.SigningFailed:
      cta = {
        heading: phrases("CtaHeading"),
        url: "/",
      };
      heading = phrases("SigningFailedHeading");
      introduction = phrases("SigningFailedText");
      break;
    case ErrorPageType.SigningExpired:
      cta = {
        heading: phrases("CtaHeading"),
        url: "/",
      };
      heading = phrases("SigningExpiredHeading");
      introduction = phrases("SigningExpiredText");
      break;
  }

  return (
    <Section
      style={{
        height: "100vh",
      }}
      theme="blue"
    >
      <Hero
        ctas={[
          {
            heading: cta.heading,
            url: cta.url,
          },
        ]}
        heading={heading}
        headingSize="XL"
        introduction={introduction}
        theme="blue"
      />
    </Section>
  );
}

export default ErrorPage;
