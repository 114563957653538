import { useEffect, useState } from "react";
import QRCode, { QRCodeToDataURLOptions } from "qrcode";

export default function QrCode({ token }: { token: string }) {
  const qrCodeOptions: QRCodeToDataURLOptions = {
    width: 200,
    margin: 1,
    errorCorrectionLevel: "L",
  };

  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    QRCode.toDataURL(token, qrCodeOptions, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }
      setImageUrl(url);
    });
  }, [token]);

  return (
    <>
      {imageUrl && (
        <img height={200} width={200} src={imageUrl} alt="QR Code" />
      )}
    </>
  );
}
