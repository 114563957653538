import { useState, useEffect, useContext } from "react";
import { LabelM, StockChart, styleBodyS, Typography } from "@sop/ui-library";
import { StyledContainer } from "../Common/Container.styled";
import { StyledCallToAction } from "../Common/CallToAction.styled";
import { useT } from "../../../shared/internationalization";
import useIsMobile, { redirectUrls } from "../../../shared/utils";
import { GraphContainer, GraphSection } from "./WealthSection.styled";
import { CultureContext } from "@sop/unify-ui-react";
import Snackbar, { SnackBarState } from "../Snackbar";
import {
  ChipOptions,
  CustomOptions,
  KeyFigures,
  PERIODS,
  SeriesData,
} from "./WealthSection.interface";
import Chips from "./Chips";
import WealthAccordion from "./WealthAccordion";
import useWealthQuery from "../../../hooks/useWealthQuery";
import usePensionPrognosisQuery from "../../../hooks/usePensionPrognosisQuery";

export default function WealthSection() {
  const { data, isPending, isError } = useWealthQuery();

  const pensionPrognosisData = usePensionPrognosisQuery();

  const phrases = useT("WealthSection");
  const culture = useContext(CultureContext).culture as "sv-SE" | "en-US";
  const isMobile = useIsMobile();
  const [options, setCustomOptions] = useState<CustomOptions>();
  const [chartFullData, setChartFullData] = useState<SeriesData[]>([]);
  const [keyFigures, setKeyFigures] = useState<KeyFigures>({
    marketValue: 0,
    development: 0,
    developmentSinceStart: 0,
    developmentSinceStartPercentage: 0,
    availableFunds: 0,
    dividends: 0,
    deposits: 0,
  });
  const [chipData, setChipData] = useState<ChipOptions[]>([
    { id: PERIODS.THREE_MONTHS, number: 0, selected: false },
    { id: PERIODS.SIX_MONTHS, number: 0, selected: true },
    { id: PERIODS.YTD, number: 0, selected: false },
    { id: PERIODS.ONE_YEAR, number: 0, selected: false },
    { id: PERIODS.THREE_YEARS, number: 0, selected: false },
    { id: PERIODS.FIVE_YEARS, number: 0, selected: false },
    { id: PERIODS.SINCE_START, number: 0, selected: false },
  ]);

  useEffect(() => {
    if (options) {
      setCustomOptions({
        ...options,
        series: [
          {
            ...options.series[0],
            name: phrases("PrivateCapital"),
          },
        ],
      });
    }
  }, [culture, phrases]);

  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });

  const { alertTitle, message, open, severity } = snackbarState;

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(() => {
    if (isError) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: phrases("SnackbarErrorMessage"),
        alertTitle: phrases("SnackbarErrorTitle"),
      });
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      if (!data?.success) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: phrases("SnackbarErrorMessage"),
          alertTitle: phrases("SnackbarErrorTitle"),
        });
      } else {
        setChartFullData(data.data.seriesData);
        let fixReturnSixMonths = data.data.fixedReturns.find(
          (x) => x.period === PERIODS.SIX_MONTHS
        );
        setChipData(
          chipData.map((chip) => {
            let fixReturn = data.data.fixedReturns.find(
              (x) => x.period === chip.id
            );
            return {
              ...chip,
              selected:
                (chip.id === PERIODS.SIX_MONTHS && fixReturn?.return) ||
                (chip.id === PERIODS.SINCE_START && !fixReturnSixMonths?.return)
                  ? true
                  : false,
              number: fixReturn ? fixReturn.return * 100 : 0,
            };
          })
        );
        let seriesData = data.data.seriesData;
        if (fixReturnSixMonths) {
          seriesData = data.data.seriesData.filter((x) => {
            const date = new Date(x.date);
            return (
              date > new Date(new Date().setMonth(new Date().getMonth() - 6))
            );
          });
        }
        setCustomOptions({
          time: {
            useUTC: false,
          },
          xAxis: {
            gridLineWidth: 0,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            pointFormat: `<div><span style="color:{series.color};font-size: 1rem;${styleBodyS}">{series.name}</span>: <span style="font-size: 1rem;${styleBodyS}">&nbsp;&nbsp;{point.change}%</span></div>`,
          },
          plotOptions: {
            series: {
              animation: {
                duration: 1000,
              },
            },
          },
          series: [
            {
              name: phrases("PrivateCapital"),
              type: "spline",
              data: seriesData.map((x) => {
                const date = new Date(x.date);
                return [date.getTime(), x.value];
              }),
            },
          ],
        });
        setKeyFigures(data.data.keyFigures);
      }
    }
  }, [data]);

  const handleChipClick = (id: number) => {
    if (options) {
      setChipData(
        chipData.map((chip) => {
          if (chip.id === id) {
            return { ...chip, selected: true };
          } else {
            return { ...chip, selected: false };
          }
        })
      );
      let seriesData = chartFullData.filter((x) => {
        const date = new Date(x.date);
        switch (id) {
          case PERIODS.THREE_MONTHS:
            return (
              date > new Date(new Date().setMonth(new Date().getMonth() - 3))
            );
          case PERIODS.SIX_MONTHS:
            return (
              date > new Date(new Date().setMonth(new Date().getMonth() - 6))
            );
          case PERIODS.YTD:
            return date.getFullYear() === new Date().getFullYear();
          case PERIODS.ONE_YEAR:
            return (
              date >
              new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            );
          case PERIODS.THREE_YEARS:
            return (
              date >
              new Date(new Date().setFullYear(new Date().getFullYear() - 3))
            );
          case PERIODS.FIVE_YEARS:
            return (
              date >
              new Date(new Date().setFullYear(new Date().getFullYear() - 5))
            );
          case PERIODS.SINCE_START:
            return true;
          default:
            return false;
        }
      });
      setCustomOptions({
        ...options,
        series: [
          {
            ...options.series[0],
            data: seriesData.map((x) => {
              const date = new Date(x.date);
              return [date.getTime(), x.value];
            }),
          },
        ],
      });
      setKeyFigures({
        ...keyFigures,
        development: chipData.find((x) => x.id === id)?.number ?? 0,
      });
    }
  };

  return (
    <>
      <StyledContainer theme="lightgray">
        <Snackbar
          open={open}
          severity={severity}
          message={message}
          alertTitle={alertTitle}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <StyledCallToAction
          contentTheme="black"
          heading={phrases("SectionTitle")}
          linkText=""
          text={!isMobile ? phrases("SectionDescription") : ""}
          url={redirectUrls.wealthBaseUrl}
        />

        <GraphSection theme="white">
          <WealthAccordion
            loading={isPending}
            culture={culture}
            keyFigures={keyFigures}
            totalPensionCapital={
              pensionPrognosisData.data?.data?.totalPensionCapital ?? 0
            }
            totalPensionCapitalLoading={pensionPrognosisData.isPending}
          ></WealthAccordion>
          <LabelM>{phrases("GraphSectionTitle")}</LabelM>
          <Typography variant="body-s">
            <i>{phrases("GraphSectionDescription")}</i>
          </Typography>
          <GraphContainer>
            <StockChart
              loading={isPending}
              customOptions={options}
              culture={culture}
            />
          </GraphContainer>
        </GraphSection>
        <Chips chipData={chipData} handleChipClick={handleChipClick}></Chips>
      </StyledContainer>
    </>
  );
}
