const getFileName = (
  contentDisposition: string | null,
  customFileName: string | ""
) => {
  if (customFileName) {
    return customFileName;
  }
  const fileName =
    contentDisposition?.split("filename=")[1].split(";")[0] || customFileName;

  return fileName;
};

export async function downloadFile(response: Response, customFileName = "") {
  const fileName = getFileName(
    response.headers.get("Content-Disposition"),
    customFileName
  );
  const blob = await response.blob();
  const a = document.createElement("a");
  const blobURL = URL.createObjectURL(blob);
  a.download = fileName;
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
