import { SignColorNumber, ThemeType } from "@sop/ui-library";
import { LabelContainer } from "./WealthSection.styled";
import useIsMobile from "../../../shared/utils";
import { useT } from "../../../shared/internationalization";
import { PERIODS } from "./WealthSection.interface";

export default function ChipLabel({
  color,
  selected,
  number,
  id,
}: {
  color: ThemeType;
  selected: boolean;
  number: number;
  id: number;
}) {
  const isMobile = useIsMobile();
  const phrases = useT("WealthSection");
  let theme = color;
  if (selected) theme = "blue";

  let text = "";
  switch (id) {
    case PERIODS.THREE_MONTHS:
      text = phrases("ThreeMonth");
      break;
    case PERIODS.SIX_MONTHS:
      text = phrases("SixMonth");
      break;
    case PERIODS.YTD:
      text = phrases("YTD");
      break;
    case PERIODS.ONE_YEAR:
      text = phrases("OneYear");
      break;
    case PERIODS.THREE_YEARS:
      text = phrases("ThreeYear");
      break;
    case PERIODS.FIVE_YEARS:
      text = phrases("FiveYear");
      break;
    case PERIODS.SINCE_START:
      text = phrases("SinceStart");
      break;
    default:
      text = "";
      break;
  }

  return (
    <LabelContainer
      isMobile={isMobile}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      {text}
      {number ? (
        <SignColorNumber number={number} animated={false} theme={theme} />
      ) : (
        <div>-</div>
      )}
    </LabelContainer>
  );
}
