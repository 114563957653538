import { useQuery } from "@tanstack/react-query";
import { getWealthSection } from "../shared/services/spPlusService";
import { usePermissions } from "../security";

const useWealthQuery = () => {
  const permissions = usePermissions();

  return useQuery({
    queryKey: ["getWealthSection"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getWealthSection();
      return result;
    },
    enabled:
      permissions.isWealthCustomer || permissions.isPensionAdvisoryCustomer,
  });
};

export default useWealthQuery;
