export const currencyFormatter = (
  culture: string,
  currency: string,
  value: number
) => {
  const formatter = new Intl.NumberFormat(culture, {
    style: "currency",
    maximumFractionDigits: 0,
    currency,
  });
  return formatter.format(value);
};

export const dateFormatter = (culture: string, date: string) => {
  const newDate = new Date(date);
  const dateFormatter = new Intl.DateTimeFormat(culture);
  const formattedDate = dateFormatter.format(newDate);
  return formattedDate;
};
