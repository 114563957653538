import { useQuery } from "@tanstack/react-query";
import { getPensionPrognosisGraph } from "../shared/services/spPlusService";
import { usePermissions } from "../security";

const usePensionPrognosisQuery = () => {
  const permissions = usePermissions();

  return useQuery({
    queryKey: ["getPensionPrognosisGraph"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getPensionPrognosisGraph();
      return result;
    },
    enabled: permissions.isPensionCustomer,
  });
};

export default usePensionPrognosisQuery;
