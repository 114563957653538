import { TranslationToolsContext } from "@sop/unify-ui-react";
import React, { useMemo } from "react";

export const useT = (context: string) => {
  const { t } = React.useContext(TranslationToolsContext);
  const tWithoutContext = useMemo(
    () =>
      (
        phrase: string,
        variables?: { [key: string]: string | number } | undefined
      ) =>
        t(context, phrase, variables),
    [t, context]
  );
  return tWithoutContext;
};
