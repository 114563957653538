import { UserInformation } from "../interfaces";

export const getUserInformation = async () => {
  const response = await fetch("unify/ui/userinformation");
  if (!response.ok) {
    return;
  }
  const userinformation: UserInformation = await response
    .json()
    .then((data) => {
      return data;
    });
  return userinformation;
};
