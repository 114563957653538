import {
  Dialog,
  Button,
  DialogContent,
  HeadingM,
  BodyM,
  HeadingS,
  Link,
} from "@sop/ui-library";
import {
  InitiateCreateAccountResult,
  Status,
  GetCreateAccountStatusResult,
} from "./BankIdDialog.interface";
import QrCode from "../QrCode";
import { getCreateAccountStatus } from "../../../shared/services/spPlusService";
import { Query, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BottomContainer,
  QrCodeSection,
  StyledCard,
  TopContainer,
} from "./BankIdDialog.styled";
import { useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useT } from "../../../shared/internationalization";
import { ROUTES } from "../../../shared/router/router.constants";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

interface BankIdDialogProps {
  sourceSystem: string;
  isOpen: boolean;
  handleClose: () => void;
  signingResult?: InitiateCreateAccountResult;
}

export default function BankIdDialog({
  sourceSystem = "Online",
  isOpen,
  handleClose,
  signingResult,
}: BankIdDialogProps) {
  const phrases = useT("SpPlus");

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { trackEvent } = useMatomo();

  if (!isOpen) {
    queryClient.removeQueries({ queryKey: ["getSigningProcessStatus"] });
  }

  const shouldRefetch = (
    result: Query<
      GetCreateAccountStatusResult,
      Error,
      GetCreateAccountStatusResult,
      string[]
    >
  ) => {
    if (
      result?.state.data?.status == Status.CANCELLED ||
      result?.state.data?.status == Status.EXPIRED ||
      result?.state.data?.status == Status.COMPLETE_CREATED_ACCOUNT ||
      result?.state.data?.status == Status.FAILED_CREATING_ACCOUNT
    ) {
      return false;
    }
    return true;
  };

  const { data } = useQuery({
    queryKey: ["getSigningProcessStatus"],
    queryFn: async () => {
      const result = await getCreateAccountStatus(signingResult?.signingId);
      return result;
    },
    refetchOnWindowFocus: false,
    refetchInterval: (refetchData) =>
      shouldRefetch(refetchData) ? 1000 : false,
    enabled: isOpen,
  });

  const token = data?.refreshedSecurityCode;

  const signingCancelled = data?.status === Status.CANCELLED;

  const signingExpired = data?.status === Status.EXPIRED;

  const signingFailedCreatingAccount =
    data?.status === Status.FAILED_CREATING_ACCOUNT;

  const signingCreatedAccount =
    data?.status === Status.COMPLETE_CREATED_ACCOUNT;

  const transactionAlreadyInProgress = !signingResult?.securityCodeToken;

  const handleStatus = (status: boolean, path: string) => {
    if (status) {
      queryClient.removeQueries({ queryKey: ["getSigningProcessStatus"] });
      navigate(path);
    }
  };

  useEffect(() => {
    handleStatus(signingFailedCreatingAccount, ROUTES.ERROR);
    handleStatus(signingCancelled, ROUTES.CANCELLED);
    handleStatus(
      signingExpired || transactionAlreadyInProgress,
      ROUTES.EXPIRED
    );
  }, [
    signingFailedCreatingAccount,
    signingCancelled,
    signingExpired,
    transactionAlreadyInProgress,
    navigate,
  ]);

  const redirectUrl = signingResult?.sameDeviceStartUrl;

  if (signingCreatedAccount) {
    trackEvent({
      name: "S&P Plus - Sign up",
      action: `Signed up for S&P Plus from ${sourceSystem}`,
      category: "S&P Plus",
    });
    return <Navigate to={ROUTES.PLUS} replace />;
  }

  return (
    <>
      <Dialog open={isOpen} fullScreen theme="cyan">
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <TopContainer
            style={{
              width: 600,
              color: "var(--color-cyan-l60)",
              position: "relative",
            }}
          >
            <StyledCard theme="blue">
              <Button
                actionTheme="cyan"
                icon="close28"
                iconOnly
                onClick={handleClose}
                size="small"
                style={{
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                }}
                variant="outline"
              />
              <HeadingM>{phrases("BankIdDialogHeading")}</HeadingM>
              <BodyM>{phrases("BankIdDialogDescription")}</BodyM>
              <BottomContainer>
                <div>
                  <HeadingS>
                    {phrases("BankIdDialogInstructionsHeading")}
                  </HeadingS>
                  <ul>
                    <li>{phrases("BankIdDialogInstructionsItemOne")}</li>
                    <li>{phrases("BankIdDialogInstructionsItemTwo")}</li>
                    <li>{phrases("BankIdDialogInstructionsItemThree")}</li>
                    <li>{phrases("BankIdDialogInstructionsItemFour")}</li>
                  </ul>
                  <HeadingS style={{ paddingBottom: "1rem" }}>
                    {phrases("BankIdDialogStartDeviceHeading")}
                  </HeadingS>
                  {redirectUrl && token && (
                    <Link href={redirectUrl} target="_blank">
                      {phrases("BankIdDialogStartDeviceLinkText")}
                    </Link>
                  )}
                </div>
                {signingResult?.securityCodeToken && token && (
                  <QrCodeSection>
                    <QrCode token={token} />
                  </QrCodeSection>
                )}
              </BottomContainer>
            </StyledCard>
          </TopContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
