import {
  Container,
  Section,
  BulletList,
  styleBodyL,
  Typography,
} from "@sop/ui-library";
import styled from "styled-components";

export const Heading = styled(Typography)`
  && {
    max-width: 350px;
    @media (min-width: 1365px) {
      max-width: 550px;
    }
  }
`;

export const MainContainer = styled(Container)`
  gap: 1.5rem;
  @media (min-width: 768px) {
    gap: 0;
  }
`;

export const LeftContainer = styled(Container)`
  && {
    @media (min-width: 768px) {
      padding: 2rem 4rem 2rem 0;
      grid-column: 1 / span 6;
    }
    @media (min-width: 1365px) {
      padding-right: 8rem;
    }
  }
`;

export const RightContainer = styled(Container)`
  && {
    padding-top: 0;
    @media (min-width: 768px) {
      grid-column: 7 / span 6;
    }
    @media (min-width: 1365px) {
    }
  }
`;

export const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
`;

export const StyledBulletList = styled(BulletList)`
  && {
    margin: 0;
    li {
      @media (min-width: 1365px) {
        ${styleBodyL};
      }
    }
    li:first-child {
      margin-top: 0;
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to top,
    var(--color-blue-d10) 0%,
    transparent 50%
  );
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const BottomText = styled(Section)``;
