import { Document28, External28, Link, styleBodyM } from "@sop/ui-library";
import styled from "styled-components";

export const Heading = styled.div`
  ${styleBodyM};
  font-weight: bold;
  grid-column: col-start 1 / span 4;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export const Links = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 0.875rem;
  margin-top: 1.5rem;
`;

export const StyledLink = styled(Link)`
  && {
    span {
      ${styleBodyM};
    }
  }
`;

export const StyledLinkIcon = styled(External28)`
  && {
    margin-right: 7px;
    top: -1px;
    position: relative;
    width: 28px;
    height: 28px;
    display: inline;
  }
`;

export const StyledDocumentIcon = styled(Document28)`
  && {
    margin-right: 7px;
    top: -1px;
    position: relative;
    width: 28px;
    height: 28px;
    display: inline;
  }
`;
