import { Container, PageCardSection } from "@sop/ui-library";
import { usePermissions } from "../../security";
import { useT } from "../../shared/internationalization";
import { redirectUrls } from "../../shared/utils";

export default function MyServices() {
  const permissions = usePermissions();

  const cardSectionPhrases = useT("CardSection");

  const pageCardSectionArgs = {
    id: "CardSection",
    theme: "white" as const,
    title: cardSectionPhrases("Title"),
    pageCardList: [
      ...(permissions.isCompanyWebUsers
        ? [
            {
              heading: cardSectionPhrases("CompanyWeb"),
              introduction: cardSectionPhrases("CompanyWebDescription"),
              url: redirectUrls.companyWebBaseUrl,
              target: "_blank",
              watermark: "pension" as const,
              image: {
                decorative: true,
                overlay: "UpLeft" as const,
                darken: true,
                url: "/images/companyWeb.jpg",
                caption: cardSectionPhrases("CompanyWeb"),
              },
            },
          ]
        : []),
      ...(permissions.isPensionCustomer
        ? [
            {
              heading: cardSectionPhrases("PensionInsurance"),
              introduction: cardSectionPhrases("PensionInsuranceDescription"),
              url: redirectUrls.pensionBaseUrl,
              watermark: "pension" as const,
              image: {
                decorative: true,
                overlay: "UpLeft" as const,
                darken: true,
                url: "/images/pension.jpg",
                caption: cardSectionPhrases("PensionInsurance"),
              },
            },
          ]
        : []),
      ...(permissions.isWealthCustomer || permissions.isPensionAdvisoryCustomer
        ? [
            {
              heading: cardSectionPhrases("AssetsWealth"),
              introduction: cardSectionPhrases("AssetsWealthDescription"),
              url: redirectUrls.wealthBaseUrl,
              watermark: "wealth" as const,
              image: {
                decorative: true,
                overlay: "UpLeft" as const,
                darken: true,
                url: "/images/wealth.jpg",
                caption: cardSectionPhrases("AssetsWealth"),
              },
            },
          ]
        : []),
      ...(permissions.isPayrollCustomer
        ? [
            {
              heading: cardSectionPhrases("PayrollHR"),
              introduction: cardSectionPhrases("PayrollHRDescription"),
              url: redirectUrls.payrollBaseUrl,
              watermark: "hr" as const,
              image: {
                decorative: true,
                overlay: "UpLeft" as const,
                darken: true,
                url: "/images/payroll.jpg",
                caption: cardSectionPhrases("PayrollHR"),
              },
            },
          ]
        : []),
      ...(permissions.isBenefitsCustomer
        ? [
            {
              heading: cardSectionPhrases("Benefits"),
              introduction: cardSectionPhrases("BenefitsDescription"),
              url: redirectUrls.benefitsBaseUrl,
              watermark: "benefits" as const,
              image: {
                decorative: true,
                overlay: "UpLeft" as const,
                darken: true,
                url: "/images/benefits.jpg",
                caption: cardSectionPhrases("Benefits"),
              },
            },
          ]
        : []),
      ...(permissions.isPrivateInsuranceUsers
        ? [
            {
              heading: cardSectionPhrases("PrivateInsurance"),
              introduction: cardSectionPhrases("PrivateInsuranceDescription"),
              url: redirectUrls.privateInsuracesBaseUrl,
              watermark: "insurance" as const,
            },
          ]
        : []),
    ],
  };

  return (
    <>
      <Container theme={pageCardSectionArgs.theme}>
        <PageCardSection {...pageCardSectionArgs} />
      </Container>
    </>
  );
}
