import React, { useEffect, useState } from "react";
import TopMenu from "./TopMenu/TopMenu";
import LeftMenu from "./LeftMenu/LeftMenu";
import { useIsTablet } from "../../shared/utils";
import MenuProps from "./MenuProps";
import { Spacer } from "./Menu.styled";

const Menu = ({ children }: MenuProps) => {
  const isMobile = useIsTablet();
  const [open, setOpen] = useState(false);

  const changeMenu = (newState: boolean) => {
    setOpen(newState);
  };

  useEffect(() => {
    if (isMobile && open) {
      setOpen(false);
    }
  }, [isMobile]);

  return (
    <div style={{ display: "flex" }}>   
      <LeftMenu
        isMobile={isMobile}
        open={open}
        onClick={() => changeMenu(false)}
      />
      <TopMenu isMobile={isMobile} onMenuClick={() => changeMenu(true)} />
      <div id="maincontent" style={{ display: "block", width: "100%" }}>
        {isMobile && <Spacer />}
        {children}
      </div>
    </div>
  );
};

export default Menu;
