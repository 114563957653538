import { CallToAction } from "@sop/ui-library";
import styled from "styled-components";

export const StyledCallToAction = styled(CallToAction)`
  && a {
    border-top: none;
    padding-top: 0;
    margin-bottom: 1rem;
  }
`;
