import {
  Container,
  LogoSmallDefault42,
  Menu28,
  styleBodyM,
} from "@sop/ui-library";
import styled from "styled-components";

export const StyledContainer = styled(Container)<{
  isMobile: boolean;
  hover: boolean;
}>`
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  ${(props) =>
    props.isMobile
      ? "height: 4.375rem;background: var(--background-default);"
      : "height: 5.5rem;background: var(--color-black-o30);"}
  z-index: 2;
  display: flex;
  transition: background 0.3s;
  ${(props) =>
    props.hover
      ? "background: var(--background-default);border-bottom: 1px solid var(--color-black-o15);"
      : ""}
`;

export const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  gap: 1.5rem;
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;

export const UserMenuWrapper = styled.button<{
  isMobile: boolean;
  hover: boolean;
}>`
  border: none;
  background: none;
  :focus-visible {
    outline-offset: -6px;
    #unify-usermenu-header circle {
      fill: var(--color-blue-o15);
      stroke: var(--color-blue-o15);
    }
  }
  #unify-usermenu-header {
    width: auto;
    ${(props) =>
      props.hover || props.isMobile ? "" : "color: var(--color-white);"}
    ${(props) => (props.isMobile ? `height: 4.375rem;` : ` height: 5.5rem; `)}
    > svg:first-child {
      margin-right: 0.45rem;
      ${(props) =>
        props.isMobile
          ? `height: 2rem; width: 2rem;`
          : `height: 3.5rem; width: 3.5rem;`}
    }
    circle {
      r: 180px;
      transition: fill 0.3s, stroke 0.3s;
      ${(props) =>
        props.hover || props.isMobile
          ? "fill: var(--overlay-neutral-darken);stroke: var(--overlay-neutral-darken);"
          : "fill: var(--color-white-o15);stroke: var(--color-white-o15);"}
    }
    text {
      ${(props) =>
        props.hover || props.isMobile
          ? "fill: var(--text-on-neutral-primary);stroke: var(--text-on-neutral-primary);"
          : "fill: var(--text-on-neutral-inverted);stroke: var(--text-on-neutral-inverted);"}
      transition: fill 0.3s, stroke 0.3s;
      font-size: 4rem;
      font-weight: 700;
    }
    :hover {
      circle {
        fill: var(--color-blue-o15);
        stroke: var(--color-blue-o15);
      }
    }
  }
  > div > div {
    ${(props) =>
      props.isMobile
        ? `
            width: 100%; 
            top: 4.4375rem; 
            position: fixed; 
            height: 100%;
        `
        : `
            width: 21.875rem; 
            box-shadow: 0px 28px 28px 0px rgba(0, 0, 0, 0.20); 
            top: 5.5625rem;
        `}
    border-radius: 4px;
    background: var(--background-default);
  }
  > div > div > ul {
    border: none;
    display: grid;
    gap: 0.5rem;
    padding: 1.3125rem 0;
    box-shadow: none;
  }
  > div > div > ul li {
    text-align: left;
    padding: 0 2rem;
    transition: transform 0.2s;
  }
  > div > div > ul li > span {
    padding: 0;
  }
  > div > div > ul li a:hover {
    border-bottom: 1px solid var(--color-black);
  }
  > div > div > ul li.divider {
    margin: 0.8125rem 0;
    background: rgba(51, 41, 44, 0.15);
  }
  > div > div a {
    ${styleBodyM}
    color: var(--color-black);
    font-feature-settings: "salt" on;
    padding: 0;
    display: inline-block;
    border-bottom: 1px solid transparent;
  }
`;

export const MenuButton = styled(Menu28)`
  height: 4.375rem;
  cursor: pointer;
  :hover {
    background: var(--blue-l30);
  }
`;

export const LogoButton = styled(LogoSmallDefault42)`
  height: 4.375rem;
`;
