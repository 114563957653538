import styled from "styled-components";
import { Card } from "@sop/ui-library";

export const GraphSection = styled(Card)`
  && {
    display: grid;
    gap: 1rem;
  }
`;

export const GraphContainer = styled.div`
  @media (min-width: 768px) {
    padding-top: 1rem;
  }
`;
