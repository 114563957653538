import { Card } from "@sop/ui-library";
import styled from "styled-components";

export const BottomContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

export const TopContainer = styled.div`
  width: 600;
  color: "var(--color-cyan-l60)";
  position: "relative";
`;

export const QrCodeSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
`;
