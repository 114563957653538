import { update } from "@sop/unify-ui-react/dist/notification/notificationFunctions";
import {
  InitiateCreateAccountResult,
  GetCreateAccountStatusResult,
  Status,
} from "../../components/Plus/BankIdDialog/BankIdDialog.interface";
import {
  GetAccountInfoResult,
  UpdateAccountInfoRequest,
  UpdateAccountInfoResult,
} from "../../components/Plus/ContactInformationDialog/ContactInformationDialog.interface";
import { PensionPrognosisGraphResultData } from "../../components/Plus/PensionPrognosisGraph/PensionPrognosisGraph.interface";
import { InsuranceResultData } from "../../components/Plus/PrivateInsurance/Insurances.interface";
import { GetSpPlusCustomerInfoResult } from "../../components/Plus/SpPlusBanner/SpPlusBanner.interface";
import { WealthSectionResultData } from "../../components/Plus/Wealth/WealthSection.interface";

export const getSpPlusCustomerInfo = async () => {
  const response = await fetch("api/SpPlus/GetSpPlusCustomerInfo");
  if (!response.ok) {
    return { success: false } as GetSpPlusCustomerInfoResult;
  }
  const data = (await response.json()) as GetSpPlusCustomerInfoResult;
  return data;
};

export const getPrivateInsurances = async () => {
  const response = await fetch("api/SpPlus/GetPrivateInsurances");
  const data = (await response.json()) as InsuranceResultData;
  return data;
};

export const getPolicyLetter = async (insuranceId: string) => {
  const response = await fetch(
    "api/SpPlus/GetPolicyLetter?" +
      new URLSearchParams({ insuranceId: insuranceId })
  );
  return response;
};

export const getPensionPrognosisGraph = async () => {
  const response = await fetch("api/SpPlus/GetPensionPrognosisGraphData");
  const data = (await response.json()) as PensionPrognosisGraphResultData;
  return data;
};

export const initiateCreateAccount = async () => {
  const response = await fetch("api/SpPlus/InitiateCreateAccount");
  if (!response.ok) {
    return { success: false } as InitiateCreateAccountResult;
  }
  const data = (await response.json()) as InitiateCreateAccountResult;
  data.success = true;
  return data;
};

export const getCreateAccountStatus = async (signingId?: string) => {
  if (!signingId) throw new Error("signingId is required");
  const response = await fetch(
    "api/SpPlus/GetCreateAccountStatus?" +
      new URLSearchParams({ signingId: signingId })
  );

  if (!response.ok) {
    return {
      status: Status.FAILED_CREATING_ACCOUNT,
    } as GetCreateAccountStatusResult;
  }

  const data = (await response.json()) as GetCreateAccountStatusResult;

  return data;
};

export const getAgreementText = async () => {
  const response = await fetch("api/SpPlus/GetAgreementText");
  const data = (await response.text()) as string;
  return data;
};

export const getAccountInformation = async () => {
  const response = await fetch("api/SpPlus/GetAccountInfo");
  const data = (await response.json()) as GetAccountInfoResult;
  return data;
};

export const updateAccountInformation = async (
  updateRequest: UpdateAccountInfoRequest
) => {
  const response = await fetch("api/SpPlus/UpdateAccountInfo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateRequest),
  });

  if (!response.ok) {
    return { success: false } as UpdateAccountInfoResult;
  }

  const data = (await response.json()) as UpdateAccountInfoResult;
  return data;
};

export const getWealthSection = async () => {
  const response = await fetch("api/SpPlus/GetWealthData");
  const data = (await response.json()) as WealthSectionResultData;
  return data;
};

export const getSpPlusAgreementFile = async () => {
  const response = await fetch("api/SpPlus/GetSpPlusAgreementFile");
  return response;
};
