import {
  Alert,
  AlertTitle,
  Button,
  Snackbar as SnackbarUILibrary,
} from "@sop/ui-library";
import { SeverityType } from "./Snackbar.type";

type SnackbarProps = {
  open: boolean;
  severity: SeverityType;
  message: string;
  alertTitle: string;
  handleCloseSnackbar: (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
};

export default function Snackbar({
  open,
  alertTitle,
  message,
  severity,
  handleCloseSnackbar,
}: SnackbarProps) {
  return (
    <SnackbarUILibrary
      open={open}
      autoHideDuration={10000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div>
        <Alert
          severity={severity}
          action={
            <Button
              aria-label="close"
              icon="close28"
              size="small"
              onClick={handleCloseSnackbar}
            />
          }
        >
          <AlertTitle> {alertTitle}</AlertTitle>
          {message}
        </Alert>
      </div>
    </SnackbarUILibrary>
  );
}
