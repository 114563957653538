import { Card, Container, FactBox, FactBoxSection } from "@sop/ui-library";
import { useT } from "../../shared/internationalization";
import { downloadFile, redirectUrls } from "../../shared/utils";
import {
  Heading,
  Links,
  StyledDocumentIcon,
  StyledLink,
  StyledLinkIcon,
} from "./MyInformation.styled";
import { getSpPlusAgreementFile } from "../../shared/services/spPlusService";
import Snackbar, { SnackBarState } from "../Plus/Snackbar";
import { useState } from "react";

export default function MyInformation({
  isSpPlus = false,
}: {
  isSpPlus?: boolean;
}) {
  const factBoxSectionPhrases = useT("FactBoxSection");

  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });

  const { alertTitle, message, open, severity } = snackbarState;

  async function fetchSpPlusAgreementDocument() {
    try {
      const response = await getSpPlusAgreementFile();
      if (!response.ok) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorMessage"),
          alertTitle: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarErrorTitle"
          ),
        });
      } else {
        await downloadFile(
          response,
          `${factBoxSectionPhrases("SpPlusAgreementDocumentText")}.pdf`
        );
        setSnackbarState({
          open: true,
          severity: "success",
          message: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarSuccessMessage"
          ),
          alertTitle: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarSuccessTitle"
          ),
        });
      }
    } catch {
      setSnackbarState({
        open: true,
        severity: "error",
        message: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorMessage"),
        alertTitle: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorTitle"),
      });
    }
  }
  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const myDocumentsCard = isSpPlus ? (
    <Card>
      <Heading>{factBoxSectionPhrases("MyDocuments")}</Heading>
      <p>{factBoxSectionPhrases("MyDocumentsDescription")}</p>
      <Links>
        <StyledLink
          id="spPlusDocument"
          key={"spPlusDocument"}
          hoverSpan={true}
          underline="none"
          onClick={(e) => {
            fetchSpPlusAgreementDocument();
            e.preventDefault();
          }}
        >
          <StyledDocumentIcon aria-hidden="true" />
          <span>{factBoxSectionPhrases("SpPlusAgreementDocumentText")}</span>
        </StyledLink>
        <StyledLink
          to={`${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`}
          id="MyDocumentsLink"
          key={"MyDocumentsLink"}
          target="_blank"
          hoverSpan={true}
          underline="none"
        >
          <StyledLinkIcon aria-hidden="true" />
          <span>{factBoxSectionPhrases("MyDocuments")}</span>
        </StyledLink>
      </Links>
    </Card>
  ) : (
    <FactBox
      anchorName="factbox-my-documents"
      heading={factBoxSectionPhrases("MyDocuments")}
      links={[
        {
          title: factBoxSectionPhrases("MyDocuments"),
          url: `${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`,
        },
      ]}
    >
      <p>{factBoxSectionPhrases("MyDocumentsDescription")}</p>
    </FactBox>
  );

  const factBoxSectionArgs = {
    theme: "lightgray" as const,
    title: factBoxSectionPhrases("Title"),
    children: [
      <FactBox
        anchorName="factbox-my-profile"
        heading={factBoxSectionPhrases("MyProfile")}
        links={[
          {
            title: factBoxSectionPhrases("MyProfile"),
            url: redirectUrls.informationCollectionBaseUrl,
          },
        ]}
      >
        <p>{factBoxSectionPhrases("MyProfileDescription")}</p>
      </FactBox>,
      myDocumentsCard,
      <FactBox
        anchorName="factbox-my-cases"
        heading={factBoxSectionPhrases("MyCases")}
        links={[
          {
            title: factBoxSectionPhrases("MyCases"),
            url: redirectUrls.myCasesBaseUrl,
          },
        ]}
      >
        <p>{factBoxSectionPhrases("MyCasesDescription")}</p>
      </FactBox>,
    ],
  };

  return (
    <>
      <Container theme={factBoxSectionArgs.theme}>
        <Snackbar
          open={open}
          severity={severity}
          message={message}
          alertTitle={alertTitle}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <FactBoxSection {...factBoxSectionArgs} />
      </Container>
    </>
  );
}
