export enum ErrorType {
  FetchingInsurances = 0,
  NoNationalIdentificationNumber = 1,
}

export enum InsuranceType {
  VILLA_HOME = "Villahem",
  HOME_INSURANCE = "Hemförsäkring",
  PASSENGER_CAR = "Personbil",
  SNOW_MOBILE = "Snöskoter",
  MOPED = "Moped",
  TRAILER = "Släp",
  GROUPMOTOR_CAR = "Bilförsäkring",
  GROUPMOTOR_TRAILER = "Släpvagn",
  GROUPMOTOR_SNOW_MOBILE = "Snöskoter och terränghjuling",
}
