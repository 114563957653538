export enum ErrorType {
  FetchingData = 0,
  NoNationalIdentificationNumber = 1,
}

export interface WealthSectionResultData {
  success: boolean;
  data: WealthSectionData;
}

export interface WealthSectionData {
  fixedReturns: FixedReturns[];
  seriesData: SeriesData[];
  keyFigures: KeyFigures;
}

export interface FixedReturns {
  period: number;
  return: number;
}

export interface SeriesData {
  date: Date;
  value: number;
}

export interface KeyFigures {
  marketValue: number;
  development: number;
  developmentSinceStart: number;
  developmentSinceStartPercentage: number;
  availableFunds: number;
  dividends: number;
  deposits: number;
}

export interface CustomOptions {
  time: Time;
  legend: Legend;
  series: Series[];
  xAxis: XAxis;
  tooltip: Tooltip;
  plotOptions: PlotOptions;
}

export interface PlotOptions {
  series: {
    animation: {
      duration: number;
    };
  };
}

export interface Tooltip {
  pointFormat: string;
}

export interface XAxis {
  gridLineWidth: number;
}

export interface Time {
  useUTC: boolean;
}

export interface Series {
  type: string;
  name: string;
  data: number[][];
}

export interface Legend {
  enabled: boolean;
}

export interface ChipOptions {
  id: number;
  selected: boolean;
  number: number;
}

export const PERIODS = {
  THREE_MONTHS: 4,
  SIX_MONTHS: 5,
  YTD: 6,
  ONE_YEAR: 7,
  THREE_YEARS: 8,
  FIVE_YEARS: 9,
  SINCE_START: 10,
} as const;
