import { useState, useEffect } from "react";

const TABLET_BREAKPOINT = 992;

function useIsTablet() {
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= TABLET_BREAKPOINT
  );

  useEffect(() => {
    function handleResize() {
      setIsTablet(window.innerWidth <= TABLET_BREAKPOINT);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isTablet;
}

export default useIsTablet;
