import { CultureContext } from "@sop/unify-ui-react";
import { useContext } from "react";
import { Footer as FooterUILibrary } from "@sop/ui-library";
import { useT } from "../../shared/internationalization";
import { redirectUrls } from "../../shared/utils";

export default function Footer({ isSpPlus = false }: { isSpPlus?: boolean }) {
  const footerPhrases = useT("Footer");

  const language = useContext(CultureContext).culture === "en-US" ? "/en" : "";

  const processingPersonalDataUrl = isSpPlus
    ? "/om-oss/personuppgifter-cookies/personuppgifter/plus/"
    : `${language}/om-oss/personuppgifter-cookies/personuppgifter/`;

  const args = {
    logoTitle: "S&P",
    navigation: {
      identifier: "footer",
      items: [
        {
          displayName: footerPhrases("QuickLinks"),
          properties: {},
          items: [
            {
              displayName: footerPhrases("AboutSP"),
              url: `${redirectUrls.soderbergPartnersBaseUrl}${language}/om-oss/organisation/`,
              properties: {},
              items: [],
              target: "_blank",
            },
            ...(!isSpPlus
              ? [
                  {
                    displayName: footerPhrases("LegalInformation"),
                    url: `${redirectUrls.soderbergPartnersBaseUrl}${language}/om-oss/organisation/koncern/juridisk-information/`,
                    properties: {},
                    items: [],
                    target: "_blank",
                  },
                ]
              : []),
            {
              displayName: footerPhrases("Mortgage"),
              url: redirectUrls.mortgagesBaseUrl,
              properties: {},
              items: [],
              target: "_blank",
            },
          ],
        },
        {
          displayName: footerPhrases("MyPages"),
          properties: {},
          items: [
            {
              displayName: footerPhrases("MyProfile"),
              url: redirectUrls.informationCollectionBaseUrl,
              properties: {},
              items: [],
            },
            {
              displayName: footerPhrases("MyDocuments"),
              url: `${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`,
              properties: {},
              items: [],
            },
            {
              displayName: footerPhrases("MyCases"),
              url: redirectUrls.myCasesBaseUrl,
              properties: {},
              items: [],
            },
          ],
        },
      ],
    },
    bottomLinks: {
      identifier: "bottomLinks",
      items: [
        {
          displayName: footerPhrases("ProcessingPersonalData"),
          url: `${redirectUrls.soderbergPartnersBaseUrl}${processingPersonalDataUrl}`,
          properties: {
            title: "PersonalData",
          },
          target: "_blank",
        },
        {
          displayName: footerPhrases("UpdateCookieConsents"),
          url: `${redirectUrls.informationCollectionBaseUrl}/yourconsents`,
          properties: {
            title: "CookieConsents",
          },
        },
      ],
    },
  };

  return <FooterUILibrary {...args} />;
}
