import {
  TableListRow,
  Section,
  TableList,
  ProductCardList,
  Icon,
  BodyM,
  ProductCardItem,
} from "@sop/ui-library";
import { CultureContext } from "@sop/unify-ui-react";
import { useContext, useState, useEffect } from "react";
import { useT } from "../../../shared/internationalization";
import {
  dateFormatter,
  currencyFormatter,
  downloadFile,
  redirectUrls,
} from "../../../shared/utils";
import { ErrorType, InsuranceType } from "./Insurances.enum";
import { Insurance } from "./Insurances.interface";
import useIsMobile from "../../../shared/utils";
import { StyledContainer } from "../Common/Container.styled";
import { StyledCallToAction } from "../Common/CallToAction.styled";
import {
  getPolicyLetter,
  getPrivateInsurances,
} from "../../../shared/services/spPlusService";
import Snackbar, { SnackBarState } from "../Snackbar";

export default function Insurances() {
  const phrases = useT("PrivateInsurancesSection");
  const culture = useContext(CultureContext).culture;

  const [properties, setProperties] = useState<Insurance[]>([]);
  const [vehicles, setVehicles] = useState<Insurance[]>([]);
  const [fetchingInsurances, setFetchingInsurances] = useState(false);
  const [fetchingInsurancesError, setFetchingInsurancesError] = useState(false);

  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });

  const { alertTitle, message, open, severity } = snackbarState;

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    async function fetchData() {
      setFetchingInsurances(true);
      try {
        const data = await getPrivateInsurances();

        if (!data.success && data.errorType === ErrorType.FetchingInsurances) {
          setFetchingInsurancesError(true);
        } else {
          setProperties(
            data?.insurances?.filter(
              (i) =>
                i.insuranceType === InsuranceType.VILLA_HOME ||
                i.insuranceType === InsuranceType.HOME_INSURANCE
            )
          );
          setVehicles(
            data?.insurances?.filter(
              (i) =>
                i.insuranceType === InsuranceType.PASSENGER_CAR ||
                i.insuranceType === InsuranceType.SNOW_MOBILE ||
                i.insuranceType === InsuranceType.MOPED ||
                i.insuranceType === InsuranceType.TRAILER ||
                i.insuranceType === InsuranceType.GROUPMOTOR_CAR ||
                i.insuranceType === InsuranceType.GROUPMOTOR_TRAILER ||
                i.insuranceType === InsuranceType.GROUPMOTOR_SNOW_MOBILE
            )
          );
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchData().then(() => setFetchingInsurances(false));
  }, []);

  async function fetchPolicyLetter(insuranceId: string) {
    try {
      const response = await getPolicyLetter(insuranceId);

      if (!response.ok) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: phrases("SnackbarErrorMessage"),
          alertTitle: "Error",
        });
      }

      await downloadFile(response);
      setSnackbarState({
        open: true,
        severity: "success",
        message: phrases("SnackbarSuccessMessage"),
        alertTitle: "Success",
      });
    } catch {
      setSnackbarState({
        open: true,
        severity: "error",
        message: phrases("SnackbarErrorMessage"),
        alertTitle: "Error",
      });
    }
  }

  const getRightDescription = (
    cancelled: string,
    periodStart: string,
    periodFinish: string
  ) => {
    const from = new Date(periodStart).getTime();
    const to = new Date(periodFinish).getTime();
    const dateToCheck = new Date().getTime();
    const isUpcomingInsurance = !(dateToCheck >= from && dateToCheck <= to);

    const getPhrase = () => {
      if (isUpcomingInsurance && cancelled === null) {
        return phrases("Upcoming");
      }

      return cancelled === null ? phrases("Active") : phrases("Expired");
    };

    const getColor = () => {
      if (isUpcomingInsurance && cancelled === null) {
        return "var(--color-traffic-yellow)";
      }

      return cancelled === null
        ? "var(--color-traffic-green)"
        : "var(--color-traffic-red)";
    };

    return (
      <>
        <span style={{ color: getColor() }}>{getPhrase()}</span>{" "}
        {!isMobile && (
          <>
            {dateFormatter(culture, periodStart)} -{" "}
            {dateFormatter(culture, periodFinish)}
          </>
        )}
      </>
    );
  };

  const getInsuranceTypeIcon = (type: string) => {
    switch (type) {
      case InsuranceType.VILLA_HOME:
        return "villa";
      case InsuranceType.HOME_INSURANCE:
        return "house";
      case InsuranceType.PASSENGER_CAR:
      case InsuranceType.TRAILER:
      case InsuranceType.MOPED:
      case InsuranceType.SNOW_MOBILE:
      case InsuranceType.GROUPMOTOR_CAR:
      case InsuranceType.GROUPMOTOR_TRAILER:
      case InsuranceType.GROUPMOTOR_SNOW_MOBILE:
        return "car";
      default:
        return "";
    }
  };
  const getInsuranceTypeText = (type: string) => {
    switch (type) {
      case InsuranceType.VILLA_HOME:
        return phrases("VillaHome");
      case InsuranceType.HOME_INSURANCE:
        return phrases("HomeInsurance");
      case InsuranceType.PASSENGER_CAR:
        return phrases("PassengerCar");
      case InsuranceType.TRAILER:
        return phrases("Trailer");
      case InsuranceType.MOPED:
        return phrases("Moped");
      case InsuranceType.SNOW_MOBILE:
        return phrases("Snowmobile");
      case InsuranceType.GROUPMOTOR_CAR:
        return phrases("GroupMotorCar");
      case InsuranceType.GROUPMOTOR_SNOW_MOBILE:
        return phrases("GroupMotorSnowMobile");
      case InsuranceType.GROUPMOTOR_TRAILER:
        return phrases("GroupMotorTrailer");
      default:
        return "";
    }
  };

  const getPaymentFrequencyText = (paymentFrequency: number) => {
    switch (paymentFrequency) {
      case 1:
        return phrases("PaymentFrequencyMonthly");
      case 3:
        return phrases("PaymentFrequencyQuarterly");
      case 6:
        return phrases("PaymentFrequencySemiannual");
      case 12:
        return phrases("PaymentFrequencyAnnual");
      default:
        return "";
    }
  };

  const getProductCard = (item: Insurance) => {
    const listRows = () => {
      let result = [
        <TableListRow key={"policyHolder"} title={phrases("PolicyHolder")}>
          {item.insuredName}
        </TableListRow>,
        <TableListRow key={"insuranceNumer"} title={phrases("InsuranceNumber")}>
          {item.insuranceNumber}
        </TableListRow>,
        <TableListRow key={"insuranceName"} title={phrases("InsuranceName")}>
          {item.insuredObject}
        </TableListRow>,
        <TableListRow
          key={"insurancePeriod"}
          title={phrases("InsurancePeriod")}
        >
          {isMobile ? (
            <>
              <div>{dateFormatter(culture, item.periodStart)}</div>
              <div>{dateFormatter(culture, item.periodFinish)}</div>
            </>
          ) : (
            <>
              {dateFormatter(culture, item.periodStart)} -{" "}
              {dateFormatter(culture, item.periodFinish)}
            </>
          )}
        </TableListRow>,
        <TableListRow key={"actualCost"} title={phrases("ActualCost")}>
          {currencyFormatter(culture, "SEK", item.actualCost)}
        </TableListRow>,
        <TableListRow
          key={"paymentFrequency"}
          title={phrases("PaymentFrequency")}
        >
          {getPaymentFrequencyText(item.paymentFrequency)}
        </TableListRow>,
      ];

      if (item.cancelled === null) {
        result.push(
          <TableListRow key={"policyLetter"} title={phrases("PolicyLetter")}>
            <a
              href="/#"
              onClick={(e) => {
                fetchPolicyLetter(item.id);
                e.preventDefault();
              }}
            >
              <Icon icon={"document28"} aria-hidden="true" />
              {phrases("DownloadPolicyLetter")}
            </a>
          </TableListRow>
        );
      }

      return result;
    };

    return (
      <ProductCardItem
        description={item.insuredObject}
        figure={item.insuranceNumber}
        icon={getInsuranceTypeIcon(item.insuranceType)}
        rightDescription={getRightDescription(
          item.cancelled,
          item.periodStart,
          item.periodFinish
        )}
        title={getInsuranceTypeText(item.insuranceType)}
        key={item.insuranceNumber}
      >
        <Section style={{ maxWidth: "540px" }}>
          <TableList title={phrases("InsuranceInformation")}>
            {listRows()}
          </TableList>
        </Section>
      </ProductCardItem>
    );
  };

  const showProperties = properties?.length > 0;
  const showVehicles = vehicles?.length > 0;

  const renderProductCardList = () => {
    const result: JSX.Element[] = [];

    if (showProperties) {
      result.push(
        <ProductCardList
          key={"property"}
          theme="white"
          title={phrases("Property")}
        >
          {properties.map((item) => {
            return getProductCard(item);
          })}
        </ProductCardList>
      );
    }

    if (showVehicles) {
      result.push(
        <ProductCardList
          key={"vehicle"}
          theme="white"
          title={phrases("Vehicle")}
        >
          {vehicles.map((item) => {
            return getProductCard(item);
          })}
        </ProductCardList>
      );
    }

    if (result.length === 0) {
      result.push(
        <ProductCardList
          key={"noInsurances"}
          theme="white"
          title={phrases("NoInsurances")}
        ></ProductCardList>
      );
    }
    return result;
  };

  return (
    <>
      <StyledContainer theme="lightgray">
        <Snackbar
          open={open}
          severity={severity}
          message={message}
          alertTitle={alertTitle}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <StyledCallToAction
          contentTheme="black"
          heading={phrases("PrivateInsurancesHeadingText")}
          linkText=""
          text={!isMobile ? phrases("PrivateInsurancesDescriptionText") : ""}
          url={redirectUrls.privateInsuracesBaseUrl}
        />
        {fetchingInsurances && (
          <>
            {Array.from({ length: 3 }).map((_, i) => (
              <ProductCardList theme="white" loading={true} key={i} />
            ))}
          </>
        )}
        {!fetchingInsurances &&
          !fetchingInsurancesError &&
          renderProductCardList()}
        {fetchingInsurancesError && (
          <BodyM style={{ fontStyle: "italic" }}>
            {phrases("FetchingInsurancesErrorMessage")}
          </BodyM>
        )}
      </StyledContainer>
    </>
  );
}
