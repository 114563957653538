import { Typography, BodyS, Section, Link } from "@sop/ui-library";
import SignUpDialog from "../SignUpDialog";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  LeftContainer,
  StyledSection,
  StyledBulletList,
  RightContainer,
  TextContainer,
  ImageOverlay,
  MainContainer,
  ImageContainer,
  Heading,
  BottomText,
} from "./SpPlusBanner.styled";
import useIsMobile, { useIsTablet } from "../../../shared/utils";
import { useT } from "../../../shared/internationalization";

export default function SpPlusBanner() {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  let [searchParams, setSearchParams] = useSearchParams();
  const phrases = useT("SpPlus");

  const showSignUpDialog = searchParams.get("signup") === "true";
  const showSignUpDialogSourceSystem =
    searchParams.get("sourcesystem") || "Online";

  useEffect(() => {
    searchParams.has("signup") && searchParams.delete("signup");
    searchParams.has("sourcesystem") && searchParams.delete("sourcesystem");
    setSearchParams(searchParams);
  }, [setSearchParams]);
  return (
    <MainContainer theme="blue">
      <Section theme="blue">
        <Heading variant={isTablet && !isMobile ? "heading-m" : "heading-l"}>
          {phrases("SpPlusBannerHeader")}
        </Heading>
      </Section>
      <LeftContainer theme="blue" hasPadding={false}>
        <StyledSection theme="blue">
          <TextContainer>
            <Typography variant="body-m">
              <StyledBulletList checkmark theme="blue">
                {phrases("SpPlusBannerItems")
                  .split("\n")
                  .map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
              </StyledBulletList>
            </Typography>
            <SignUpDialog
              sourceSystem={showSignUpDialogSourceSystem}
              showOnLoad={showSignUpDialog}
            />
            <BodyS>
              {phrases("SpPlusBannerExplanation")}{" "}
              <Link
                href={phrases("SpPlusBannerLinkUrl")}
                target="_blank"
                underline="always"
              >
                {phrases("SpPlusBannerLinkText")}
              </Link>
            </BodyS>
          </TextContainer>
        </StyledSection>
      </LeftContainer>
      <RightContainer hasPadding={false}>
        <ImageContainer>
          <img
            src="/images/homeSoPPlus.jpg"
            style={{
              width: "100%",
            }}
          />
          <ImageOverlay />
        </ImageContainer>
      </RightContainer>
      <BottomText>
        <Typography variant="body-s">
          {phrases("SpPlusBannerOrganization")}
        </Typography>
      </BottomText>
    </MainContainer>
  );
}
