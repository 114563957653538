import { Banner, Container } from "@sop/ui-library";
import { useT } from "../../shared/internationalization";
import { redirectUrls } from "../../shared/utils";
import Footer from "../Footer/Footer";
import SpPlusBanner from "../Plus/SpPlusBanner";
import MyServices from "../MyServices";
import MyInformation from "../MyInformation";
import { useCustomerInfo } from "../../App";

export default function Dashboard() {
  const customerInfo = useCustomerInfo();

  const bannerPhrases = useT("Banner");

  const bannerArgs = {
    heading: bannerPhrases("Title"),
    text: bannerPhrases("Text"),
    contentTheme: "white" as const,
    url: `${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`,
    linkText: bannerPhrases("LinkText"),
    backgroundImage: {
      url: "/images/banner.jpg",
      decorative: true,
      overlay: "UpLeft" as const,
      darken: false,
    },
    transparentMenuGap: true,
  };

  return (
    <>
      <Banner {...bannerArgs} />
      <MyServices />
      {customerInfo?.shouldShowBanner && <SpPlusBanner />}
      <MyInformation />
      <Footer />
    </>
  );
}
