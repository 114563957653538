import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { usePermissions } from "./security";
import { useT } from "./shared/internationalization";
import { createContext, useContext, useEffect, useState } from "react";
import getMatomoUserOptions from "./shared/analytics/matomo";
import {
  InstanceParams,
  MatomoInstance,
} from "@jonkoops/matomo-tracker-react/lib/types";
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { redirectUrls } from "./shared/utils";
import MatomoWrapper from "./components/MatomoWrapper/MatomoWrapper";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "./shared/localStorage";
import { UsersnapProvider } from "./shared/usersnap";
import Plus from "./components/Plus";
import { getSpPlusCustomerInfo } from "./shared/services/spPlusService";
import { useLocation, Navigate } from "react-router-dom";
import { ROUTES } from "./shared/router/router.constants";
import { SpPlusCustomerInfo } from "./components/Plus/SpPlusBanner/SpPlusBanner.interface";
const CustomerInfoContext = createContext<SpPlusCustomerInfo | null>(null);

function App() {
  const permissions = usePermissions();
  let loadPage = false;
  const phrases = useT("Common");

  const { pathname } = useLocation();

  const shouldRedirect: Boolean | null = JSON.parse(
    getLocalStorageWithExpiry("shouldRedirect")
  );

  if (shouldRedirect === null) {
    const twoHoursInMilliSeconds: number = 7200000;
    setLocalStorageWithExpiry(
      "shouldRedirect",
      "false",
      twoHoursInMilliSeconds
    );
  }

  switch (true) {
    case shouldRedirect === false:
      loadPage = true;
      break;
    case permissions.showHomePage:
      loadPage = true;
      break;
    case permissions.isCompanyWebUsers:
      loadPage = true;
      break;
    case permissions.isWealthCustomer:
      window.location.href = redirectUrls.wealthBaseUrl as string;
      break;
    case permissions.isPensionCustomer:
      loadPage = true;
      break;
    case permissions.isBenefitsCustomer:
      window.location.href = redirectUrls.benefitsBaseUrl as string;
      break;
    case permissions.isPayrollCustomer:
      window.location.href = redirectUrls.payrollBaseUrl as string;
      break;
    case permissions.isGroupCustomer:
      window.location.href = redirectUrls.groupBaseUrl as string;
      break;
    case permissions.isPensionNonAdvisoryCustomer:
      loadPage = true;
      break;
    default:
      loadPage = true;
      break;
  }

  const [matomoUserOptions, setMatomoUserOptions] =
    useState<InstanceParams | null>(null);

  const [spPlusCustomerInfo, setSpPlusCustomerInfo] =
    useState<SpPlusCustomerInfo | null>(null);

  useEffect(() => {
    document.title = phrases("Title");
    async function retrieveMatomoUserOptions() {
      const matomoUserOptions: InstanceParams = await getMatomoUserOptions();
      setMatomoUserOptions(matomoUserOptions);
    }

    async function checkIfSpPlusCustomer() {
      const info = await getSpPlusCustomerInfo();
      if (!info.success) {
        setSpPlusCustomerInfo({
          isSpPlusCustomer: false,
          shouldShowBanner: false,
        } as SpPlusCustomerInfo);
      }
      setSpPlusCustomerInfo(info.data);
    }

    checkIfSpPlusCustomer();
    retrieveMatomoUserOptions();
  }, [phrases, pathname]);

  if (matomoUserOptions === null || spPlusCustomerInfo === null) {
    return <></>;
  }

  const matomoProviderProps: MatomoInstance = createInstance(matomoUserOptions);

  let content = <Dashboard />;

  const pathNameToLowerCase = pathname?.toLocaleLowerCase();

  if (pathNameToLowerCase === ROUTES.PLUS && spPlusCustomerInfo.isSpPlusCustomer) {
    content = <Plus />;
  }

  if (pathNameToLowerCase === ROUTES.PLUS && !spPlusCustomerInfo.isSpPlusCustomer) {
    content = <Navigate to={ROUTES.BASE} replace />;
  }

  if (pathNameToLowerCase === ROUTES.BASE && spPlusCustomerInfo.isSpPlusCustomer) {
    content = <Navigate to={ROUTES.PLUS} replace />;
  }

  return (
    <>
      {loadPage && (
        <>
          <UsersnapProvider>
            <MatomoProvider value={matomoProviderProps}>
              <MatomoWrapper>
                <CustomerInfoContext.Provider value={spPlusCustomerInfo}>
                  <div className="App">{content}</div>
                </CustomerInfoContext.Provider>
              </MatomoWrapper>
            </MatomoProvider>
          </UsersnapProvider>
        </>
      )}
    </>
  );
}

export const useCustomerInfo = () => {
  const context = useContext(CustomerInfoContext);
  if (context === undefined) {
    throw new Error('useCustomerInfo must be used within a CustomerInfoProvider');
  }
  return context;
};

export default App;
