import { CultureType, KeyFigureSection } from "@sop/ui-library";
import { useT } from "../../../shared/internationalization";

export default function PensionAccordion({
  culture,
  loading,
  totalPensionCapital,
  currentSalary,
}: {
  culture: CultureType;
  loading: boolean;
  totalPensionCapital: number;
  currentSalary: number;
}) {
  const phrases = useT("PensionPrognosisGraphSection");

  return (
    <KeyFigureSection
      anchorName="keyfigure-anchor-header"
      display="grid"
      culture={culture}
      keyFigures={[
        {
          loading: loading,
          keyFigure: totalPensionCapital,
          prefix:
            culture === "en-US"
              ? `${phrases("SwedishCurrencyShort")} `
              : undefined,
          suffix:
            culture === "sv-SE"
              ? ` ${phrases("SwedishCurrencyShort")}`
              : undefined,
          text: phrases("TotalPensionCapital"),
          animated: true,
          culture: culture,
        },
        {
          loading: loading,
          keyFigure: currentSalary,
          suffix: " " + phrases("GraphCurrencyLabel"),
          text: phrases("CurrentSalary"),
          animated: true,
          culture: culture,
        },
      ]}
      identifier="751cc379-eb67-4132-9bb0-01ea55bdb240"
    />
  );
}
